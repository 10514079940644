import axios from "axios";

const state = {
  bidder_data_of_opportunity: {
    addBidders:[],
    loader_Loading_C:[],
    loader_Loading_T:[],
  },
  copyStateValue_bidder: {
    addBidders:[],
    loader_Loading_C:[],
    loader_Loading_T:[],
  },
  all_Bidders: "",
};
const mutations = {
  SET_BIDDERS_DETAILS_OF_OPPORTUNITY(state, data) {
    state.bidder_data_of_opportunity.addBidders.length = 0
    state.bidder_data_of_opportunity.loader_Loading_C.length = 0
    state.bidder_data_of_opportunity.loader_Loading_T.length = 0
    
    data.forEach((element) => {
      console.log('element',element);
      // console.log("bidders_by_ID", element, index);
      // this.bidders_by_ID = res.data.data;
      state.bidder_data_of_opportunity.addBidders.push({
          partner_venue_name: element.account_detail,
          account_id: element.account_id,
          bidder_id: element.bidder_id,
          email: element.email,
          history: element.history != "" ? JSON.parse(element.history) : [],
          index_priority: element.index_priority,
          invited: element.invited,
          is_outside_partner: element.is_outside_partner,
          name: element.name,
          opportunity_id: element.opportunity_id,
          preliminary: element.preliminary,
          preliminary_booking: element.preliminary_booking,
          preliminary_booking_id: element.preliminary_booking_id,
          present_to_customer: element.present_to_customer,
          quote_id: element.quote_id,
          resend_invitation: element.resend_invitation,
          send_invitation: element.send_invitation,
          status: element.status,
          disabled: false,
          tooltip_title:'Click to Check Preliminary Status'
        });
        state.bidder_data_of_opportunity.loader_Loading_C.push({
          send: false,
          resend: false,
          booking: false,
          delete: false,
        });
        state.bidder_data_of_opportunity.loader_Loading_T.push({
          send: true,
          resend: true,
          booking: true,
          delete: true,
        });
    });
    state.bidder_data_of_opportunity.addBidders.sort((a, b) => a.index_priority - b.index_priority);

},
  UPDATE_BIDDER_DETAILS_OF_OPPORTUNITY(state, payload) {
    console.log('UPDATE_BIDDER_DETAILS_OF_OPPORTUNITY',payload);
    payload.forEach((element) => {
      console.log('element',element);
      // console.log("bidders_by_ID", element, index);
      // this.bidders_by_ID = res.data.data;
      state.copyStateValue_bidder.addBidders.push({
          partner_venue_name: element.account_detail,
          status: element.status,
          bidder_id: element.bidder_id,
          present_to_customer: element.present_to_customer,
          send_invitation: element.send_invitation,
          resend_invitation: element.resend_invitation,
          opportunity_id: element.opportunity_id,
          quote_id: element.quote_id,
          account_id: element.account_id,
          preliminary: element.preliminary,
          is_outside_partner: element.is_outside_partner,
          name: element.name,
          email: element.email,
          invited: element.invited,
          disabled: false
        });
        state.copyStateValue_bidder.loader_Loading_C.push({
          send: false,
          resend: false,
          booking: false,
          delete: false,
        });
        state.copyStateValue_bidder.loader_Loading_T.push({
          send: true,
          resend: true,
          booking: true,
          delete: true,
        });
    });
    state.bidder_data_of_opportunity.addBidders = state.copyStateValue_bidder.addBidders;
    state.bidder_data_of_opportunity.loader_Loading_C = state.copyStateValue_bidder.loader_Loading_C;
    state.bidder_data_of_opportunity.loader_Loading_T = state.copyStateValue_bidder.loader_Loading_T;
    console.log('state.bidder_data_of_opportunity',state.bidder_data_of_opportunity);

  },
  SET_ALL_BIDDERS_DATA(state, data) {
    state.all_Bidders = data;
  },
};
const actions = {
  Get_Bidders_Opportunity_By_ID({ commit, rootGetters }, Id) {
    commit("SET_TOTAL_APIS", 1);

    // *************GET bidders by opportunity ID**********
    axios
      .get(axios.defaults.baseURL + "get-bidder/" + Id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_BIDDERS_DETAILS_OF_OPPORTUNITY", res.data.data);
        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);
      })
      .catch((err) => {
        console.log("err bidders", err);
        // this.$store.dispatch("checkErrorAndSendToast", [
        //   err.response,
        //   "error",
        // ]);
      });
  },
  Update_Bidder_Detail_Of_Opportunity({ commit, rootGetters }, payload) {
    console.log('Update_Bidder_Detail_Of_Opportunity',payload);
        commit("UPDATE_BIDDER_DETAILS_OF_OPPORTUNITY", payload);
  },
  Get_All_Bidders({ commit, rootGetters }) {
    commit("SET_TOTAL_APIS", 1);
    // *************GET bidders**********
    axios
      .get(axios.defaults.baseURL + "bidders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_ALL_BIDDERS_DATA", res.data.data);

        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);
        // this.bidders_clone = res.data.data;

        // if(data != undefined){
        //   this.addBidders.push({
        //     partner_venue_name: "",
        //     status: "Invitation not sent",
        //     present_to_customer: false,
        //     send_invitation: 1,
        //     resend_invitation: 0,
        //     opportunity_id: "",
        //     quote_id: "",
        //     account_id: data != undefined ? data.account_id : '',
        //     preliminary: 0,
        //     invited: 0,
        //     searchQuery: "",
        //     is_outside_partner: "",
        //     name: "",
        //   });
        // }

        // this.loader_Loading_C.push({
        //   send: false,
        //   resend: false,
        // });
        // this.loader_Loading_T.push({
        //   send: true,
        //   resend: true,
        // });
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
        this.value = false;
      });
  },
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
