<!-- PartnerLayout.vue -->
<template>
  <div id="main-wrapper">
    <NavHeader />
    <Header />
    <Sidebar />
    <router-view></router-view>
    <div class="footer">
      <div class="copyright">
        <p>Copyright © 2023 &amp; Konferensbokarna</p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "../components/partnerNavHeader";
import Sidebar from "../components/partnerSidebar";
import Header from "../components/partnerHeader";

export default {
  name: "Partner Layout",
  components: { NavHeader, Sidebar, Header },
};
</script>
