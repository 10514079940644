<!-- AdminLayout.vue -->
<template>
  <div id="main-wrapper">
    <NavHeader />
    <Header />
    <Sidebar />
    <router-view></router-view>
    <div class="footer">
      <div class="copyright">
        <p>Copyright © 2023 &amp; Konferensbokarna</p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "../components/navHeader";
import Sidebar from "../components/sidebar";
import Header from "../components/header";

export default {
  name: "Admin Layout",
  components: { NavHeader, Sidebar, Header },
};
</script>
