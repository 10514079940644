// Common APIs
import axios from "axios";
import English from "../../English.json";
import Swedish from "../../Swedish.json";
import router from "../router/routes";

const state = {
  user: null,
  token: null,
  loginButtonLoader: false,
  toasts: [],
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setGet_User(state, user) {
    state.user = user;
  },
  SET_LOGIN_BUTTON_LOADER_VALUE(state, newValue) {
    state.loginButtonLoader = newValue;
  },
};

const actions = {
  login({ dispatch,commit, rootGetters}, { username, password }) {
    commit("SET_LOGIN_BUTTON_LOADER_VALUE", true);
    var lan = rootGetters["whichLanguage"]
    // return
    var formData = new FormData();
    formData.append("email", username);
    formData.append("password", password);
    axios
      .post(axios.defaults.baseURL + "login", formData)
      .then((res) => {
        console.log(res);
        localStorage.setItem("token", res.data.data.token);
        // When you set the token with expiration Time
        const expirationTime = new Date().getTime() + 12 * 60 * 60 * 1000; // 12 hours in milliseconds
        localStorage.setItem("tokenExpiration", expirationTime);

        const token = res.data.data.token;
        commit("setToken", token);
        dispatch("getUserDetails", {token: token, coming:'Login'});
      })
      .catch((err) => {
        console.log('err-',err);
        commit("SET_LOGIN_BUTTON_LOADER_VALUE", false);
        if(err.message == 'Network Error'){
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          commit("addToast", {
            title: lan.Server_not_working,
            type: "error_1",
            message: err.message,
          });
        }
        // dispatch("checkErrorAndSendToast",[err.response,"error",])
        commit("addToast", {
          title: lan.Please_check_emailid_or_password,
          type: "error_1",
          message: err.response.data.message,
        });
        setTimeout(() => {
          commit("clearToast", lan.Please_check_emailid_or_password);
        }, 5000);
      });
  },
  getUserDetails({commit, rootGetters}, data){
    var lan = rootGetters["whichLanguage"]
    var formData2 = new FormData();
    formData2.append("token", data.token);
    axios
      .post(axios.defaults.baseURL + "get-user", formData2)
      .then((response) => {
        console.log("login details--", response);

        const user = {
          accountDetail: response.data.data.accountDetail[0],
          name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          address: response.data.data.address,
          roles: response.data.data.roles,
          id: response.data.data.id,
          mobile_phone: response.data.data.mobile_phone,
          phone: response.data.data.phone,
          email: response.data.data.email,
          company: response.data.data.company,
          token: localStorage.getItem("token"),
        };
        commit("setUser", user);
        localStorage.setItem("user", JSON.stringify(user));

        commit("SET_LOGIN_BUTTON_LOADER_VALUE", false);

        if(data.coming == 'Login'){
          if (response.data.data.roles == 1) {
            commit("addToast", {
              title: "Success",
              type: "success_1",
              message: lan.Welcome + user.name,
            });
            setTimeout(() => {
              commit("clearToast", lan.Welcome + user.name);
            }, 5000);
            router.push({ path: "/" });
          } else if (response.data.data.roles == 2) {
            localStorage.setItem("KonfrenceLnaguage", "Swedish");
            commit("setLanguages", Swedish);
            commit("addToast", {
              title: "Success",
              type: "success_1",
              message: lan.Welcome + user.name,
            });
            setTimeout(() => {
              commit("clearToast", lan.Welcome + user.name);
            }, 5000);
            router.push("/partner");
          } else if (response.data.data.roles == 3) {
            commit("addToast", {
              title: "Information",
              type: "info_1",
              message: lan.Customer_cant_login_any_portals,
            });
            setTimeout(() => {
              commit("clearToast", lan.Customer_cant_login_any_portals);
            }, 5000);
            router.push("/partner");
          }
        }

      })
      .catch((error) => {
        console.log(error);
        commit("addToast", {
          title: lan.Please_check_emailid_or_password,
          type: "error_1",
          message: error.response.data.message,
        });
        setTimeout(() => {
          commit("clearToast", lan.Please_check_emailid_or_password);
        }, 5000);
        commit("SET_LOGIN_BUTTON_LOADER_VALUE", false);
        if (error.response.statusText == "Unauthorized") {
          router.push("/login");
        }
      });


  },
  logout({ commit }) {
    commit("clearAuthData");

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpiration");
    router.push("/login").then(() => {
      router.go();
    });
  },
  tryAutoLogin({ commit }) {
    // Check if the user and token are stored in localStorage
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    if (token && user) {
      // Set the user and token in the store
      commit("setToken", token);
      commit("setUser", user);
    } else {
      router.push("/login");
    }
  },
};

const getters = {
  getDashboardData: (state) => state.DashboardData,
  loginButtonLoaderValue(state) {
    return state.loginButtonLoader;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
