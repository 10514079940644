<template>
  <div class="header">
    <div class="header-content">
      <nav class="navbar navbar-expand">
        <div class="collapse navbar-collapse justify-content-between">
          <div class="header-left">
            <!-- <div class="input-group search-area right d-lg-inline-flex d-none">
        <input type="text" class="form-control" placeholder="Find something here...">
        <div class="input-group-append">
          <span class="input-group-text">
            <a href="javascript:void(0)">
              <i class="flaticon-381-search-2"></i>
            </a>
          </span>
        </div>
      </div> -->
          </div>
          <ul class="navbar-nav header-right main-notification">
            <!-- <li class="nav-item dropdown notification_dropdown">
                        <a class="nav-link bell dz-theme-mode" href="#">
          <i id="icon-light" class="fa fa-sun-o"></i>
                            <i id="icon-dark" class="fa fa-moon-o"></i>
                        </a>
      </li> -->
            <!-- <li class="nav-item dropdown notification_dropdown">
                        <a class="nav-link bell dz-fullscreen" href="#">
                            <svg id="icon-full" viewbox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" style="stroke-dasharray: 37, 57; stroke-dashoffset: 0;"></path></svg>
                            <svg id="icon-minimize" width="20" height="20" viewbox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minimize"><path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" style="stroke-dasharray: 37, 57; stroke-dashoffset: 0;"></path></svg>
                        </a>
      </li> -->

            <li v-if="totalAPICalls != 0" class="nav-item dropdown notification_dropdown mr-3">
              <div class="loaderAndPercent d-flex align-items-center">
                <div class="lds-ring lds-ring-header mr-3" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="percent">{{ Progress_APIs_Calls }}%</div>
              </div>
            </li>


            <li class="nav-item dropdown notification_dropdown mr-4">
              <a href="javascript:void(0)" role="button" data-toggle="dropdown">
                <a
                  v-if="language == 'English'"
                  class="nav-link ai-icon d-flex bg-orange"
                  href="javascript:void(0)"
                >
                  <img
                    src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
                    class="flag"
                    alt=""
                  />
                  <span class="pl-2 color">En</span>
                </a>
                <a
                  v-else-if="language == 'Swedish'"
                  class="nav-link ai-icon d-flex bg-orange"
                  href="javascript:void(0)"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/1200px-Flag_of_Sweden.svg.png"
                    class="flag"
                    alt=""
                  />
                  <span class="pl-2 color">Sw</span>
                </a>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right p-0"
                style="min-width: 190px"
              >
                <div id="dlab_W_Notification1" class="widget-media p-3">
                  <ul class="timeline ml-4">
                    <li>
                      <div
                        class="timeline-panel"
                        @click="switchLanguag('English')"
                      >
                        <a
                          class="nav-link ai-icon d-flex bg-orange"
                          href="javascript:void(0)"
                        >
                          <img
                            src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
                            class="flag"
                            alt=""
                          />
                          <span class="pl-3 color">English</span>
                        </a>
                      </div>

                      <div
                        class="timeline-panel"
                        @click="switchLanguag('Swedish')"
                      >
                        <a
                          class="nav-link ai-icon d-flex bg-orange"
                          href="javascript:void(0)"
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/1200px-Flag_of_Sweden.svg.png"
                            class="flag"
                            alt=""
                          />
                          <span class="pl-3 color">Swedish</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <!-- <li
              class="nav-item dropdown notification_dropdown d-sm-flex d-none"
            >
              <a
                class="nav-link ai-icon"
                href="javascript:void(0)"
                role="button"
                data-toggle="dropdown"
              >
                <svg
                  width="30"
                  height="30"
                  viewbox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.625 6.12506H22.75V2.62506C22.75 2.47268 22.7102 2.32295 22.6345 2.19068C22.5589 2.05841 22.45 1.94819 22.3186 1.87093C22.1873 1.79367 22.0381 1.75205 21.8857 1.75019C21.7333 1.74832 21.5831 1.78629 21.4499 1.86031L14 5.99915L6.55007 1.86031C6.41688 1.78629 6.26667 1.74832 6.11431 1.75019C5.96194 1.75205 5.8127 1.79367 5.68136 1.87093C5.55002 1.94819 5.44113 2.05841 5.36547 2.19068C5.28981 2.32295 5.25001 2.47268 5.25 2.62506V6.12506H4.375C3.67904 6.12582 3.01181 6.40263 2.51969 6.89475C2.02757 7.38687 1.75076 8.0541 1.75 8.75006V11.3751C1.75076 12.071 2.02757 12.7383 2.51969 13.2304C3.01181 13.7225 3.67904 13.9993 4.375 14.0001H5.25V23.6251C5.25076 24.321 5.52757 24.9882 6.01969 25.4804C6.51181 25.9725 7.17904 26.2493 7.875 26.2501H20.125C20.821 26.2493 21.4882 25.9725 21.9803 25.4804C22.4724 24.9882 22.7492 24.321 22.75 23.6251V14.0001H23.625C24.321 13.9993 24.9882 13.7225 25.4803 13.2304C25.9724 12.7383 26.2492 12.071 26.25 11.3751V8.75006C26.2492 8.0541 25.9724 7.38687 25.4803 6.89475C24.9882 6.40263 24.321 6.12582 23.625 6.12506ZM21 6.12506H17.3769L21 4.11256V6.12506ZM7 4.11256L10.6231 6.12506H7V4.11256ZM7 23.6251V14.0001H13.125V24.5001H7.875C7.64303 24.4998 7.42064 24.4075 7.25661 24.2434C7.09258 24.0794 7.0003 23.857 7 23.6251ZM21 23.6251C20.9997 23.857 20.9074 24.0794 20.7434 24.2434C20.5794 24.4075 20.357 24.4998 20.125 24.5001H14.875V14.0001H21V23.6251ZM24.5 11.3751C24.4997 11.607 24.4074 11.8294 24.2434 11.9934C24.0794 12.1575 23.857 12.2498 23.625 12.2501H4.375C4.14303 12.2498 3.92064 12.1575 3.75661 11.9934C3.59258 11.8294 3.5003 11.607 3.5 11.3751V8.75006C3.5003 8.51809 3.59258 8.2957 3.75661 8.13167C3.92064 7.96764 4.14303 7.87536 4.375 7.87506H23.625C23.857 7.87536 24.0794 7.96764 24.2434 8.13167C24.4074 8.2957 24.4997 8.51809 24.5 8.75006V11.3751Z"
                    fill="#EB8153"
                  ></path>
                </svg>
              </a>
              
            </li> -->
            <li class="nav-item dropdown header-profile">
              <a class="nav-link" href="#" role="button" data-toggle="dropdown">
                <img v-if="!User || !User.accountDetail || !User.accountDetail.profile_picture" src="images/logos/87-512.png" alt="" />
                <img v-else :src="User.accountDetail.profile_picture" alt="" />
                <div class="header-info">
                  <span>{{ User ? User.name : '' }}</span>
                  <small>Partner</small>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link
                  to="/partner-profile"
                  class="dropdown-item ai-icon"
                >
                  <svg
                    id="icon-user1"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-primary"
                    width="30"
                    height="30"
                    viewbox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <span class="ml-2">Profile</span>
                </router-link>

                <router-link
                  to="/partner-reset-password"
                  class="dropdown-item ai-icon"
                >
                  <i class="fa fa-cog text-danger"></i>
                  <span class="ml-4">Reset Password</span>
                </router-link>
                <div
                  @click.prevent="logout"
                  class="dropdown-item ai-icon"
                  style="cursor: pointer"
                >
                  <svg
                    v-show="logout_loading_T"
                    id="icon-logout"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-danger"
                    width="18"
                    height="18"
                    viewbox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  <span v-show="logout_loading_T" class="ml-3"> Logout</span>
                  <div
                    v-show="logout_loading_L"
                    class="loadingio-spinner-rolling-yxlbb0cd94"
                  >
                    <div class="ldio-4c1f6xkxs72">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'
// import store from '../store/index';

export default {
  name: "Header",
  data() {
    return {
      logout_loading_L: false,
      logout_loading_T: true,
      loading: true,
      language: localStorage.getItem("KonfrenceLnaguage"),
    };
  },
  watch:{
    completedAPICalls: {
      immediate: true, // Trigger the watcher immediately
      handler(newVal) {
        // console.log("new value", newVal);
        // console.log("new this.totalAPICalls", this.totalAPICalls);
        if (this.totalAPICalls == newVal) {
          // console.log("reach");
          this.$store.commit("SET_RESET_DATA_OF_ALL_APIS", 0);
        }
      },
    },
  },
  computed: {
    ...mapState({
      User: (state) => state.auth.user,
      Progress_APIs_Calls: (state) => state.apiProgress.progress,
      totalAPICalls: (state) => state.apiProgress.totalAPICalls,
      completedAPICalls: (state) => state.apiProgress.completedAPICalls,
    }),
    user() {
      var user = JSON.parse(localStorage.getItem("user"));
      console.log('user header--',user);
      return user != null ? user : {name:'KonferenceBokarna'}
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.logout_loading_L = true;
      this.logout_loading_T = false;
      var formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      axios
        .post(axios.defaults.baseURL + "logout", formData, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.data.status == "Logout") {
            this.$store.dispatch("checkErrorAndSendToast", [
              res,
                "Success",
              ]);
            this.$store.dispatch("logout");
            // this.$router.push("/login");
            // this.$router.push("/login").then(() => {
            //   this.$router.go();
            // });
            this.logout_loading_L = false;
            this.logout_loading_T = true;
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          this.logout_loading_L = false;
          this.logout_loading_T = true;
        });
    },
    switchLanguag(Language) {
      this.language = Language;
      this.$store.dispatch("getLanguages", Language);
    },
  },
};
</script>
<style type="text/css">
@keyframes ldio-4c1f6xkxs72 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-4c1f6xkxs72 div {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid #e15b64;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-4c1f6xkxs72 div {
  animation: ldio-4c1f6xkxs72 0.5154639175257733s linear infinite;
  top: 50%;
  left: 50%;
}
.loadingio-spinner-rolling-yxlbb0cd94 {
  width: 25px;
  height: 25px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-4c1f6xkxs72 {
  width: 100%;
  height: 100%;
  position: relative;
  /* transform: translateZ(0) scale(0.22); */
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-4c1f6xkxs72 div {
  box-sizing: content-box;
}
</style>
