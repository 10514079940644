<template>
  <div id="nav">
    <Loader />
    <component :is="currentLayout">
      <router-view />
      <Toasts />
    </component>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Toasts from "@/components/Toasts.vue";
import Loader from "@/components/LoaderMain.vue";

export default {
  name: "App",
  components: { Toasts, Loader },
  data() {
    return {};
  },
  created() {
    // this.$store.dispatch("tryAutoLogin");
    window.addEventListener("beforeunload", this.leaving);
    localStorage.setItem("Konfrence_testing", "testing");
    var lan = localStorage.getItem("KonfrenceLnaguage");
    // console.log('app vue',lan)
    this.$store.dispatch("getLanguages", lan);
    // Schedule the token removal after 1 minute
    // setInterval(() => {
    //   this.checkTokenExpiration();
    // }, 6000);// 60,000 milliseconds = 1 minute
  },
  computed: {
    ...mapState(["user"]),
    currentLayout() {
      console.log("state user--", this.user);
      if (this.user != null) {
        return this.user.roles === 1
          ? "AdminLayout"
          : this.user.roles === 2
          ? "PartnerLayout"
          : "";
      } else {
        return "CustomerLayout";
      }
    },
  },
  methods: {
    // checkTokenExpiration() {
    //   const expirationTime = localStorage.getItem('tokenExpiration');
    //   if (expirationTime) {
    //     console.log('expirationTime app--',expirationTime);
    //     const currentTime = new Date().getTime();
    //     if (currentTime > parseInt(expirationTime, 10)) {
    //       // Token has expired, remove it from local storage
    //       localStorage.removeItem('token');
    //       localStorage.removeItem('tokenExpiration');
    //       alert("Your login session expired");
    //       this.$router.push('/login')
    //     }
    //   }
    // },
    leaving() {
      localStorage.removeItem("Konfrence_testing");
      // localStorage.removeItem("token");
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
