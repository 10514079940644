<template>
  <div class="deznav">
    <div class="deznav-scroll">
      <div class="main-profile">
        <div class="image-bx">
          <img v-if="!User || !User.accountDetail || !User.accountDetail.profile_picture" src="images/logos/87-512.png" alt="" />
          <img v-else :src="User.accountDetail.profile_picture" alt="" />
          <!-- <a href="javascript:void(0);"><i class="fa fa-cog" aria-hidden="true"></i></a> -->
        </div>
        <h5 class="name">
          <span class="font-w400"
            >{{ lan.partners[5].partner_portal[0].Hello }},</span
          >
          {{ User ? User.name : '' }}
        </h5>
      </div>
      <ul class="metismenu" id="menu">
        <li class="nav-label first">
          {{ lan.partners[5].partner_portal[0].Main_Menu }}
        </li>
        <li>
          <router-link
            class="has-arrow ai-icon"
            to="/partner"
            aria-expanded="false"
          >
            <i class="flaticon-077-menu-1"></i>
            <span class="nav-text">{{
              lan.partners[5].partner_portal[0].Dashboard
            }}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon4"
            to="/p-quotes"
            aria-expanded="false"
          >
            <i class="flaticon-003-diamond"></i>
            <span class="nav-text">{{
              lan.partners[5].partner_portal[0].Quotes
            }}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon4"
            to="/p-commission"
            aria-expanded="false"
          >
            <i class="flaticon-381-network"></i>
            <span class="nav-text">{{
              lan.partners[5].partner_portal[0].Commission
            }}</span>
          </router-link>

          <!-- <router-link
            class="has-arrow ai-icon5"
            to="/p-account"
            aria-expanded="false"
          >
            <i class="fa-regular fa-user"></i>
            <span class="nav-text">Partner accounts</span>
          </router-link> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import store from '../store/index';
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  computed: {
    ...mapState({
      User: (state) => state.auth.user,
    }),
    lan() {
      return this.$store.getters.whichLanguage;
    },
    user() {
      var user = JSON.parse(localStorage.getItem("user"));
      console.log("user header--", user);
      return user != null ? user : { name: "KonferenceBokarna" };
    },
  },
};
</script>
