import axios from "axios";

const state = {
  single_Quote_Details: "",
  all_Quotes_Details: "",
};
const mutations = {
  SET_QUOTE_DETAILS(state, data) {
    state.single_Quote_Details = data;
  },
  SET_ALL_QUOTE_DETAILS(state, data) {
    state.all_Quotes_Details = data;
  },
};
const actions = {
  GetQuoteByID({ commit, rootGetters }, Id) {
    // const totalAPICalls = 7;
    // let completedAPICalls = 0;
    commit("SET_TOTAL_APIS", 1);

    return new Promise((resolve, reject) => {
      axios
        .get(axios.defaults.baseURL + "quote/" + Id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log('quote response',res);
          commit("SET_QUOTE_DETAILS", res.data.data[0]);
          const G_Total_Apis = rootGetters["G_Total_Apis"];
          var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
          G_Completed_APICalls++;
          const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
          commit("SET_PROGRESS", progress);
          commit("SET_COMPLETED_APIS", G_Completed_APICalls);
          resolve(res.data.data[0]);
        })
        .catch((err) => {
          console.error(err);
          const G_Total_Apis = rootGetters["G_Total_Apis"];
          var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
          G_Completed_APICalls++;
          const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
          commit("SET_PROGRESS", progress);
          commit("SET_COMPLETED_APIS", G_Completed_APICalls);
          reject(err);
        });
    });
    // axios
    //   .get(axios.defaults.baseURL + "opportunity/" + Id, {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   })
    //   .then((res) => {
    //     commit("SET_OPPORTUNITY_DETAILS", res.data.data[0]);
    //     return res
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  },
  Get_Quotes({ commit, rootGetters }, Id) {
    commit("SET_TOTAL_APIS", 1);
    return new Promise((resolve, reject) => {
      axios
        .get(axios.defaults.baseURL + "opportunities", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          commit("SET_ALL_QUOTE_DETAILS", res.data.data);
          const G_Total_Apis = rootGetters["G_Total_Apis"];
          var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
          G_Completed_APICalls++;
          const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
          commit("SET_PROGRESS", progress);
          commit("SET_COMPLETED_APIS", G_Completed_APICalls);
          resolve("Done");
        })
        .catch((err) => {
          console.error(err);
        });
    });
  },
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
