// Common APIs
import axios from "axios";
import English from "../../English.json";
import Swedish from "../../Swedish.json";

const state = {
    language: "",
    languageName: "",
};

const mutations = {
    setLanguages(state, lan) {
        // console.log("setLanguages state", state);
        console.log("setLanguages lan", lan);
        state.language = lan;
      },
      setLanguagesName(state, lan) {
        // console.log("setLanguages state", state);
        // console.log("setLanguages lan", lan);
        state.languageName = lan;
      },
};

const actions = {
    getLanguages({ commit }, language) {
      // axios
      //     .get(axios.defaults.baseURL + "language", {})
      //     .then((res) => {
      //       console.log('GetLanguagesFromAPI--',res);
      //       console.log('GetLanguagesFromAPI--en--- ',JSON.parse(res.data.data[0].en));
      //       console.log('GetLanguagesFromAPI--sw--- ',JSON.parse(res.data.data[0].sw));
      //       if (language == "English") {
      //         localStorage.setItem("KonfrenceLnaguage", "English");
      //         commit("setLanguages", JSON.parse(res.data.data[0].en));
      //         // commit("setLanguages", English);
      //         commit("setLanguagesName", "English");
      //       } else {
      //         localStorage.setItem("KonfrenceLnaguage", "Swedish");
      //         commit("setLanguages", JSON.parse(res.data.data[0].sw));
      //         // commit("setLanguages", Swedish);
      //         commit("setLanguagesName", "Swedish");
      //       }

      //       // this.accountDetails = res.data.data;
      //     })
      //     .catch((err) => {
      //       console.log("err", err);
      //       this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      //     });

        if (language == "English") {
          localStorage.setItem("KonfrenceLnaguage", "English");
          commit("setLanguages", English);
          commit("setLanguagesName", "English");
        } else {
          localStorage.setItem("KonfrenceLnaguage", "Swedish");
          commit("setLanguages", Swedish);
          commit("setLanguagesName", "Swedish");
        }
      },
      GetLanguagesFromAPI() {
        axios
          .get(axios.defaults.baseURL + "language", {})
          .then((res) => {
            console.log('GetLanguagesFromAPI--',res);
            console.log('GetLanguagesFromAPI--en--- ',JSON.parse(res.data.data[0].en));
            console.log('GetLanguagesFromAPI--sw--- ',JSON.parse(res.data.data[0].sw));

            // this.accountDetails = res.data.data;
          })
          .catch((err) => {
            console.log("err", err);
            this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
          });
      }
};

const getters = {
    whichLanguage(state) {
        return state.language;
      },
      whichLanguageName(state) {
        return state.languageName;
      },
};

export default {
    state,
    mutations,
    actions,
    getters,
};