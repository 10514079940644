// Common APIs
const state = {
  progress: 0,
  totalAPICalls: 0,
  completedAPICalls: 0,

};

const mutations = {
  SET_PROGRESS(state, value) {
    state.progress = value.toFixed();
    // state.totalAPICalls = state.progress == 100 ? 0 : state.totalAPICalls;
    // console.log('SET_PROGRESS',state.progress);
  },
  SET_TOTAL_APIS(state, value) {
    state.totalAPICalls = state.totalAPICalls + value;
    // console.log('SET_TOTAL_APIS',state.totalAPICalls);
  },
  SET_COMPLETED_APIS(state, value) {
    state.completedAPICalls = value;
    // state.totalAPICalls = state.completedAPICalls == state.totalAPICalls ? 0 : state.totalAPICalls;
    // console.log('SET_COMPLETED_APIS',state.completedAPICalls);
  },
  SET_RESET_DATA_OF_ALL_APIS(state, value) {
    state.completedAPICalls = 0;
    state.totalAPICalls = 0;
    state.progress = 0;
    // state.totalAPICalls = state.completedAPICalls == state.totalAPICalls ? 0 : state.totalAPICalls;
    // console.log('SET_RESET_DATA_OF_ALL_APIS 1',state.completedAPICalls);
    // console.log('SET_RESET_DATA_OF_ALL_APIS 2',state.totalAPICalls);
    // console.log('SET_RESET_DATA_OF_ALL_APIS 3',state.progress);
  },
};

const actions = {};

const getters = {
    G_Total_Apis(state) {
        // console.log('state.totalAPICalls',state.totalAPICalls);
        return state.totalAPICalls;
      },
    G_Completed_APICalls(state) {
        // console.log('state.completedAPICalls',state.completedAPICalls);
        return state.completedAPICalls;
      },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
