<template>
  <div class="a" v-show="loader_Value">
    <div class="lds-ellipsiss">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  data() {
    return {
    //   icons: {
    //     success: "fa-solid fa-check",
    //     info: "fa-solid fa-info",
    //     warning: "fa-solid fa-exclamation",
    //     error: "fa-solid fa-xmark",
    //   },
    };
  },
  computed: {
    loader_Value() {
      return this.$store.state.Main_Loader_value;
    },
  },
  methods: {
    // dismissToast(title) {
    //   this.$store.commit("clearToast2", title );
    // },
  },
};

</script>

<style>
.a{
    background: #fff;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    width: 100%;
}
.lds-ellipsiss {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.lds-ellipsiss div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #F16E5E;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  
}
.lds-ellipsiss div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsiss div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsiss div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsiss div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

</style>
