import axios from "axios";

const state = {
  allProducts_details: "",
};
const mutations = {
  SET_ALL_PRODUCTS_DETAILS(state, data) {
    state.allProducts_details = data;
  },
};
const actions = {
  GetProducts({ commit, rootGetters }) {
    commit("SET_TOTAL_APIS", 1);
    // *************GET Products**********
    axios
      .get(axios.defaults.baseURL + "products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_ALL_PRODUCTS_DETAILS", res.data.data);
        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);

        // this.inputValueWatcher();
        // this.productOnlyOthers = res.data.data;
      })
      .catch((err) => {
        console.log("err product", err);
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });
  },
};
const getters = {
  Ge_AllProductDetails: (state) => state.allProducts_details
};

export default {
  state,
  mutations,
  actions,
  getters,
};
