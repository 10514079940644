import axios from "axios";

const state = {
  all_Accounts_Details: "",
  single_Account_Details: "",
  customer_AccountDetails: [],
  account_owners: "",
};
const mutations = {
  SET_ALL_ACCOUNTS_DETAILS(state, data) {
    state.all_Accounts_Details = data;
    state.customer_AccountDetails.length = 0;

    data.forEach((element) => {
      if (element.type == "Customer") {
        state.customer_AccountDetails.push(element);
      }
    });
  },
  SET_ACCOUNTS_OWNERS_DETAILS(state, data) {
    state.account_owners = data;
  },
  SET_ACCOUNT_DETAILS_BY_ID(state, data) {
    state.single_Account_Details = data;
  },
};
const actions = {
  GetAccounts({dispatch, commit, rootGetters }) {
    commit("SET_TOTAL_APIS", 1);
    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "accounts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_ALL_ACCOUNTS_DETAILS", res.data.data);
        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);
        // this.accountDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });
  },
  GetAccountsById({dispatch, commit, rootGetters },ID) {
    commit("SET_TOTAL_APIS", 1);
    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "account/" + ID , {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_ACCOUNT_DETAILS_BY_ID", res.data.data);
        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);
        // this.accountDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });
  },
  Get_Accounts_owner({dispatch, commit, rootGetters }) {
    commit("SET_TOTAL_APIS", 1);
    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "get-account-owners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_ACCOUNTS_OWNERS_DETAILS", res.data.data);
        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);
        // this.accountDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });
  },
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
