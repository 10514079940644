import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "../store";
// import moment from 'moment';

import AdminLayout from "../layouts/AdminLayout.vue";
import PartnerLayout from "../layouts/PartnerLayout.vue";
import CustomerLayout from "../layouts/CustomerLayout.vue";

const routes = [
  {
    path: "/",
    component: AdminLayout,
    beforeEnter: (to, from, next) => {
      console.log('store admin--', store);
      var lan = store.getters.whichLanguage
      var token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      const expirationTime = localStorage.getItem("tokenExpiration");
      if (expirationTime) {
        const currentTime = new Date().getTime();
        if (currentTime > parseInt(expirationTime, 10)) {
          // Token has expired, remove it from local storage
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          alert(lan.Your_login_session_been_expired);
          next({ name: "Login" });
        }
      }

      var isAuthenticated = Boolean(token);
      var isAuthenticatedUser = Boolean(user);
      // Middleware logic specific to this route
      if (isAuthenticated && isAuthenticatedUser && expirationTime) {
        // console.log('store.email',store.state.getUser.email);
        if (user.roles === 1) {
          if (store.state.auth.getUser == null || store.state.auth.getUser == undefined) {
            store.state.Main_Loader_value = true;
            var formData2 = new FormData();
            formData2.append("token", token);
            axios
              .post(axios.defaults.baseURL + "get-user", formData2)
              .then((response) => {
                // console.log("login details--from store--", response);
                if (response.data.data.email == user.email) {
                  const user = {
                    name: response.data.data.first_name,
                    last_name: response.data.data.last_name,
                    address: response.data.data.address,
                    roles: response.data.data.roles,
                    id: response.data.data.id,
                    mobile_phone: response.data.data.mobile_phone,
                    phone: response.data.data.phone,
                    email: response.data.data.email,
                    company: response.data.data.company,
                    token: localStorage.getItem("token"),
                  };
                  store.commit("setGet_User", user);
                  localStorage.setItem("user", JSON.stringify(user));
                  store.state.Main_Loader_value = false;
                  next();
                } else {
                  alert(lan.Your_login_session_been_expired);
                  next({ name: "Login" });
                }
              })
              .catch((err) => {
                console.log("error hai", err);
                if (
                  err.response.data.message ==
                  'Attempt to read property "tokenable" on null'
                ) {
                  alert(lan.Your_login_session_been_expired);
                }
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.go();
                setTimeout(() => {
                  store.commit(
                    "clearToast",
                    "Please check email-id or password"
                  );
                }, 5000);
                if (err.response.statusText == "Unauthorized") {
                  router.push("/login");
                }
              });
          } else {
            if (store.state.auth.getUser.email == user.email) {
              next();
            } else {
              alert(lan.Your_login_session_been_expired);
              next({ name: "Login" });
            }
            //            alert('You have already logged in as a partner')
          }
          // next();
        } else {
          alert("You are not authorized");
          next({ name: "Login" });
          // window.$nuxt.$toasted.error('You are not authorized');
        }
      } else {
        next({ name: "Login" });
      }
    },
    children: [
      {
        path: "",
        component: () => import("../views/Admin/dashboard.vue"),
        meta: { authRequired: true, title: 'Dashboard | Konferensbokarna' },
      },
      // Add more admin routes as needed
      {
        path: "/profile",
        name: "Profilee",
        // component: Dashboard,
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Admin/profile.vue"),
        meta: { authRequired: true, title: 'Profile | Konferensbokarna' },
      },
      {
        path: "/email-template",
        name: "Email Template",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/emailTemplate.vue"
          ),
        meta: { authRequired: true, title: 'Email Template | Konferensbokarna' },
      },
      {
        path: "/leads",
        name: "Leads",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Leads/leads.vue"
          ),
        meta: { authRequired: true, title: 'Leads | Konferensbokarna' },
      },
      {
        path: "/convert-lead-id=:id",
        name: "Convert Lead",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Leads/convertLead.vue"
          ),
        meta: { authRequired: true, title: 'Convert Lead | Konferensbokarna' },
      },
      {
        path: "/create-opportunity-:id",
        name: "Create Opportunity",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Leads/createOpportunity.vue"
          ),
        meta: { authRequired: true, title: 'Create Opportunity | Konferensbokarna' },
      },
      {
        path: "/accounts",
        name: "Accounts",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Accounts/account.vue"
          ),
        meta: { authRequired: true, title: 'Accounts | Konferensbokarna' },
      },
      {
        path: "/add-account",
        name: "Add Account",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Accounts/addAccount.vue"
          ),
        meta: { authRequired: true, title: 'Add Account | Konferensbokarna' },
      },
      {
        path: "/edit-account-:id",
        name: "Edit Account",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Accounts/editAccount.vue"
          ),
        meta: { authRequired: true, title: 'Edit Account | Konferensbokarna' },
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Contacts/contacts.vue"
          ),
        meta: { authRequired: true, title: 'Contact | Konferensbokarna' },
      },
      {
        path: "/add-contact",
        name: "Add Contact",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Contacts/addContact.vue"
          ),
        meta: { authRequired: true, title: 'Add Contact | Konferensbokarna' },
      },
      {
        path: "/edit-contact-:id",
        name: "Edit Contact",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Contacts/editContact.vue"
          ),
        meta: { authRequired: true, title: 'Edit Contact | Konferensbokarna' },
      },
      {
        path: "/commission",
        name: "Commission",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Commission/commission.vue"
          ),
        meta: { authRequired: true, title: 'Commission | Konferensbokarna' },
      },
      {
        path: "/edit-commission-:id",
        name: "Edit Commission",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Commission/editCommission.vue"
          ),
        meta: { authRequired: true, title: 'Edit Commission | Konferensbokarna' },
      },
      {
        path: "/notes",
        name: "Notes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Notes/notes.vue"
          ),
        meta: { authRequired: true, title: 'Notes | Konferensbokarna' },
      },
      {
        path: "/add-note",
        name: "Add Note",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Notes/addNote.vue"
          ),
        meta: { authRequired: true, title: 'Add Note | Konferensbokarna' },
      },
      {
        path: "/edit-note-:id",
        name: "Edit Note",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Notes/editNote.vue"
          ),
        meta: { authRequired: true, title: 'Edit Note | Konferensbokarna' },
      },
      {
        path: "/opportunity",
        name: "Opportunity",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Opportunity/opportunity.vue"
          ),
        meta: { authRequired: true, title: 'Opportunity | Konferensbokarna' },
      },
      {
        path: "/add-selfopportunity",
        name: "Add Self Opportunity",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Opportunity/addSelfOpportunity.vue"
          ),
        meta: { authRequired: true, title: 'Add Self Opp | Konferensbokarna' },
      },
      {
        path: "/add-opportunity&l=:id&a=:account&c=:contact",
        name: "Add Opportunity",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Opportunity/addOpportunity.vue"
          ),
        meta: { authRequired: true, title: 'Add Opportunity | Konferensbokarna' },
      },
      {
        path: "/edit-opportunity-:id",
        name: "Edit Opportunity",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Opportunity/editOpportunity.vue"
          ),
        meta: { authRequired: true, title: 'Edit Opportunity | Konferensbokarna' },
      },
      {
        path: "/products",
        name: "Products",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Products/products.vue"
          ),
        meta: { authRequired: true, title: 'Products | Konferensbokarna' },
      },
      {
        path: "/add-product",
        name: "Add Product",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Products/addProduct.vue"
          ),
        meta: { authRequired: true, title: 'Add Product | Konferensbokarna' },
      },
      {
        path: "/edit-product-:id",
        name: "Edit Product",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Products/editProduct.vue"
          ),
        meta: { authRequired: true, title: 'Edit Product | Konferensbokarna' },
      },
      {
        path: "/quotes",
        name: "Quotes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Quotes/quotes.vue"
          ),
        meta: { authRequired: true, title: 'Quotes | Konferensbokarna' },
      },
      {
        path: "/edit-quotes-:id",
        name: "Edit Quote",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Quotes/editQuote.vue"
          ),
        meta: { authRequired: true, title: 'Edit Quotes | Konferensbokarna' },
      },
      {
        path: "/tasks",
        name: "Tasks",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Tasks/tasks.vue"
          ),
        meta: { authRequired: true, title: 'Tasks | Konferensbokarna' },
      },
      {
        path: "/add-task",
        name: "Add Task",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Tasks/addTask.vue"
          ),
        meta: { authRequired: true, title: 'Add Task | Konferensbokarna' },
      },
      {
        path: "/edit-task-:id",
        name: "Edit Task",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/Tasks/editTask.vue"
          ),
        meta: { authRequired: true, title: 'Edit Tasks | Konferensbokarna' },
      },
      {
        path: "/admin-reset-password",
        name: "Admin Reset Password",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/adminPasswordReset.vue"
          ),
        meta: { authRequired: true, title: 'Reset Password | Konferensbokarna' },
      },
    ],
  },
  // *****************Partner route*************

  // ******************************************
  {
    path: "/",
    component: PartnerLayout,
    beforeEnter: (to, from, next) => {
      var lan = store.getters.whichLanguage
      var token = localStorage.getItem("token");
      var isAuthenticated = Boolean(token);
      const expirationTime = localStorage.getItem("tokenExpiration");
      if (expirationTime) {
        const currentTime = new Date().getTime();
        if (currentTime > parseInt(expirationTime, 10)) {
          // Token has expired, remove it from local storage
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          alert(lan.Your_login_session_been_expired);
          next({ name: "Login" });
        }
      }

      // Middleware logic specific to this route
      if (isAuthenticated && expirationTime) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.roles === 1) {
          alert(lan.Hi_Admin_Now_you_accessing_partne_portal);
          next();
        } 
        else if(user.roles !== 2){
          alert('Hi, You do not have permission to login to any portal, please contact Konferensbokarna');
        }
        else if(user.roles === 2) {
          if (store.state.auth.getUser == null) {
            var formData2 = new FormData();
            formData2.append("token", token);
            axios
              .post(axios.defaults.baseURL + "get-user", formData2)
              .then((response) => {
                console.log('response-route--', response);
                if (response.data.data.company == user.company) {
                  const user = {
                    accountDetail: response.data.data.accountDetail[0],
                    name: response.data.data.first_name,
                    last_name: response.data.data.last_name,
                    address: response.data.data.address,
                    roles: response.data.data.roles,
                    id: response.data.data.id,
                    mobile_phone: response.data.data.mobile_phone,
                    phone: response.data.data.phone,
                    email: response.data.data.email,
                    company: response.data.data.company,
                    token: localStorage.getItem("token"),
                  };
                  store.commit("setGet_User", user);
                  localStorage.setItem("user", JSON.stringify(user));
                  next();
                } else {
                  alert(lan.Your_login_session_been_expired);
                  next({ name: "Login" });
                }
              })
              .catch((err) => {
                console.log(err);
                if(err.message == 'Network Error'){
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  router.go();
                }
                if (
                  err.response.data.message ==
                  'Attempt to read property "tokenable" on null'
                ) {
                  alert(lan.Your_login_session_been_expired);
                }
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.go();
                setTimeout(() => {
                  store.commit(
                    "clearToast",
                    "Please check email-id or password"
                  );
                }, 5000);
                if (err.response.statusText == "Unauthorized") {
                  router.push("/login");
                }
              });
          } else {
            if (store.state.auth.getUser.company == user.company) {
              next();
            } else {
              alert(lan.Your_login_session_been_expired);
              next({ name: "Login" });
            }
            //            alert('You have already logged in as a partner')
          }
          // window.$nuxt.$toasted.error('You are not authorized');
        }
      } else {
        next({ name: "Login" });
      }
    },
    children: [
      // Add more user routes as needed
      {
        path: "partner",
        name: "Partner Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/dashboard.vue"
          ),
        meta: { authRequired: true },
      },
      {
        path: "/partner-profile",
        name: "Partner Profile",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/profile.vue"
          ),
        meta: { authRequired: true, title: 'Profile | Konferensbokarna' },
      },
      {
        path: "/partner-reset-password",
        name: "Partner Reset Password",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/resetPassword.vue"
          ),
        meta: { authRequired: true, title: 'Reset Password | Konferensbokarna' },
      },
      {
        path: "/p-quotes",
        name: "Partner Quotes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/Quotes/quotes.vue"
          ),
        meta: { authRequired: true, title: 'Quotes | Konferensbokarna' },
      },
      {
        path: "/p-editquote&id=:id",
        name: "Partner Edit Quotes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/Quotes/editQuote.vue"
          ),
        meta: { authRequired: true, title: 'Edit Quotes | Konferensbokarna' },
      },
      {
        path: "/p-account",
        name: "Partner Account",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/Account/partnerAccount.vue"
          ),
        meta: { authRequired: true, title: 'Accounts | Konferensbokarna' },
      },
      {
        path: "/p-commission",
        name: "Partner Commission",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/Commission/commission.vue"
          ),
        meta: { authRequired: true, title: 'Commission | Konferensbokarna' },
      },
      {
        path: "/p-editCommission&id=:id",
        name: "Partner Edit Commission",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Partner/Commission/editCommission.vue"
          ),
        meta: { authRequired: true, title: 'Edit Commission | Konferensbokarna' },
      },
    ],
  },

  // *****************Coustomer route*************
  {
    path: "/",
    component: CustomerLayout,
    children: [
      // *********Login Page**********
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/loginPage.vue"
          ),
          meta: {title: 'Login | Konferensbokarna' },
      },

      // *********Quote prestention preview**********
      {
        path: "/quote-presention-pre&o=:id&a=:admin",
        name: "Quote Presentation Preview",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/quotePreview.vue"
          ),
          meta: {title: 'Quote Presentation | Konferensbokarna' },
      },

      // *********Edit Quote Outside partner **********
      {
        path: "/email-editquote&id=:id",
        name: "Edit Quote Outside Partner",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/editQuote_outside.vue"
          ),
          meta: {title: 'Email Quote | Konferensbokarna' },
      },

      // *********Set Password **********
      {
        path: "/set-password&id=:id",
        name: "Set Password",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/setPassword.vue"
          ),
          meta: {title: 'Set Password | Konferensbokarna' },
      },
      // *********Reset Password **********
      {
        path: "/reset-password&id=:id",
        name: "Reset Password",
        // component: Dashboard,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/resetPassword.vue"
          ),
          meta: {title: 'Reset Password | Konferensbokarna' },
      },

      {
        path: "/preliminary-booking&o=:id&q=:qID&c_id=:cutomerID&b_id=:bidderID&p_id=:bookingID",
        name: "Preliminary Booking",
        // component: Dashboard,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Admin/preliminaryBooking.vue"
          ),
        meta: { authRequired: false, title: 'Preliminary Booking | Konferensbokarna' },
      },
    ],
  },

  // *********Landing Page Withpopup **********
  {
    path: "/send-request",
    name: "Customer Landing Page",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Admin/landingPage.vue"),
      meta: {title: 'Send Request | Konferensbokarna' },
  },
  // *********404 Page **********
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/notFound.vue"),
      meta: {title: 'Not Found | Konferensbokarna' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  var token = localStorage.getItem("token");
  var isAuthenticated = Boolean(token);

   document.title = to.meta.title || 'Konferensbokarna | Dashboard';

  if (to.meta.authRequired && !isAuthenticated) {
    next({
      name: "Login",
    });
  } else if (!to.meta.authRequired && !isAuthenticated) {
    next();
  } else {
    next();
  }
});

export default router;
