<template>
  <div class="deznav">
    <div class="deznav-scroll" style="overflow-y: auto;">
      <div class="main-profile">
        <div class="image-bx">
          <img src="images/logos/ulf.png" alt="" />
          <!-- <a href="javascript:void(0);"><i class="fa fa-cog" aria-hidden="true"></i></a> -->
        </div>
        <h5 class="name">
          <span class="font-w400">Hello,</span>{{ user.name }}
        </h5>
      </div>
      <ul class="metismenu" id="menu">
        <li class="nav-label first">Main Menu</li>
        <li>
          <router-link class="has-arrow ai-icon" to="/" aria-expanded="false">
            <i class="flaticon-077-menu-1"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Dashboard}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon2"
            to="/leads"
            aria-expanded="false"
          >
            <i class="flaticon-061-puzzle"></i>
            <span class="nav-text">{{ lan.admin[1].Leads[0].Lead}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon3"
            to="/opportunity"
            aria-expanded="false"
          >
            <i class="fa-regular fa-lightbulb"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Opportunity}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon4"
            to="/quotes"
            aria-expanded="false"
          >
            <i class="flaticon-003-diamond"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Quote}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon4"
            to="/commission"
            aria-expanded="false"
          >
            <i class="flaticon-381-network"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Commission}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon7"
            to="/tasks"
            aria-expanded="false"
          >
            <i class="flaticon-049-copy"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Tasks}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon8"
            to="/notes"
            aria-expanded="false"
          >
            <i class="fa-regular fa-note-sticky"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Notes}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon5"
            to="/accounts"
            aria-expanded="false"
          >
            <i class="fa-regular fa-user"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Accounts}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon6"
            to="/contacts"
            aria-expanded="false"
          >
            <i class="fa-regular fa-address-book"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Contacts}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon1"
            to="/products"
            aria-expanded="false"
          >
            <i class="flaticon-381-settings-2"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Products}}</span>
          </router-link>

          <router-link
            class="has-arrow ai-icon1"
            to="/email-template"
            aria-expanded="false"
          >
          <i class="fa-regular fa-envelope"></i>
            <span class="nav-text">{{ lan.admin[0].admin_portal[0].Email_Template}}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import store from '../store/index';
export default {
  name: "Sidebar",
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },

    lan(){
      return this.$store.getters.whichLanguage
    },
  },
};
</script>
