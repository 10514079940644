// Common APIs
const state = {
  toasts: [],
};

const mutations = {
  addToast(state, toast) {
    state.toasts.push(toast);
    console.log('addToast-',state,toast)
  },
  clearToast(state, title) {
    // console.log('clearToast-',state,title)
    setTimeout(() => {
      if (title != undefined) {
        const index = state.toasts.findIndex((toast) => toast.title === title); // find toast
        state.toasts.splice(index, 1); // remove toast from array
      } else {
        state.toasts.splice(0, 1); // remove toast from array
      }
    }, 5000);
  },
  clearToast2(state, title) {
    if (title != undefined) {
      const index = state.toasts.findIndex((toast) => toast.title === title); // find toast
      state.toasts.splice(index, 1); // remove toast from array
    } else {
      state.toasts.splice(0, 1); // remove toast from array
    }
  },
};

const actions = {

};

const getters = {
    
};

export default {
  state,
  mutations,
  actions,
  getters,
};
