import { createStore } from 'vuex';
// import App from "../../src/app.vue";
import editOpportunity from './modules/opportunity/editOpportunity.js';
import editQuote from './modules/quote/editQuote.js';
// import editOpportunity from './editOpportunity';
import auth from './auth.js';
import language from './language';
import verifyErrors from './verifyErrors';
import toast from './toast';
import products from './products.js';
import bidders from './bidders.js';
import accounts from './accounts.js';
import contacts from './contacts.js';
import apiProgress from './apiProgress.js';
import priliminaryBooking from './priliminaryBooking.js';
// import backup from './backup';

export default createStore({
  modules: {
    editOpportunity,
    auth,
    language,
    verifyErrors,
    toast,
    products,
    bidders,
    accounts,
    contacts,
    apiProgress,
    editQuote,
    priliminaryBooking
    // backup
    // Add more modules from the modules folder as needed
  },
});

// const app = createApp(App);
// app.use(store);
// app.mount('#app');
