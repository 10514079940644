<template>
  <div class="toasts-wrapper" v-if="toasts && toasts.length">
    <div v-for="(toast, index) in toasts" :key="index">
      <!-- {{ toast }} -->
      <div class="toast_1" :class="toast.type">
        <!-- <div class="header_1" :class="toast"> -->
        <div class="header_1">
          <!-- <h3>jasbkj</h3> -->
          <div class="tost-icon" :class="
                toast.type == 'error_1'
                  ? 'xmark'
                  : toast.type == 'warning_1'
                  ? 'exclamation'
                  : toast.type == 'info_1'
                  ? 'info'
                  : 'checkk'
              " >
            <i
              class="fa-solid"
              :class="
                toast.type == 'error_1'
                  ? 'fa-xmark xmark'
                  : toast.type == 'warning_1'
                  ? 'fa-exclamation exclamation'
                  : toast.type == 'info_1'
                  ? 'fa-info info'
                  : 'fa-check check'
              "
            ></i>
          </div>
          <h5>{{ toast.title }}</h5>
          <button @click="dismissToast(toast.title)">
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
        <div class="content_1">
          <!-- <p>{{ toast }}</p> -->
          <p>{{ toast.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Toast from "./Toast.vue";
export default {
  name: "Toasts",
  //   components: { Toast },
  data() {
    return {
      icons: {
        success: "fa-solid fa-check",
        info: "fa-solid fa-info",
        warning: "fa-solid fa-exclamation",
        error: "fa-solid fa-xmark",
      },
    };
  },
  computed: {
    toasts() {
      return this.$store.state.toast.toasts;
    },
  },
  methods: {
    dismissToast(title) {
      this.$store.commit("clearToast2", title );
    },
  },
};
</script>

<style scoped>
.toasts-wrapper {
  width: 484px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 8rem;
  right: 0;
  z-index: 1100;
}
/* :root = CSS3 way of adding variables you can use anywhere */
:root {
  --cream: #faf8f7;
  --light-grey: #e7e7e7;
  --grey: #cccccc;
  --white: #ffffff;
  --green: #7ad052;
  --red: #c32727;
  --blue: #009999;
}

.success_1,
.info,
.error {
  color: var(--white);
}

/* .success_1 {
  background-color: #7ad052;
} */

.info_1 {
  border-bottom: 4px solid #579ced;
  border-left: 10px solid #579ced;
}

.error_1 {
  border-bottom: 4px solid #c32727;
  border-left: 10px solid #c32727;
}

.toast_1 {
  background-color: #f3efef;
  width: 400px;
  min-height: 50px;
  /* display: flex; */
  flex-direction: row;
  margin-bottom: 1rem;
  padding: 10px;
  box-sizing: border-box;
  /* background-color: var(--cream); */
  justify-content: center;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 0.5rem;
  box-shadow: rgb(157 157 157 / 24%) 1px 3px 5px;
  position: relative;
}

.checkk {
  background: #7ad052;
}
.xmark {
  background: #c32727;
}
.exclamation {
  background: #fdc333;
}
.info {
  background: #579ced;
}
.tost-icon {
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 15px;
  top: 30px;
}

.tost-icon i {
  color: #fff;
  font-size: 12px;
}

.tost-icon i:hover {
  color: #fff;
}

.warning_1 {
  border-bottom: 4px solid #fdc333;
  border-left: 10px solid #fdc333;
}

.success_1 {
  border-bottom: 4px solid #8bc95b;
  border-left: 10px solid #8bc95b;
}

.toast_1 p {
  font-weight: 100;
  margin-bottom: 0px;
  color: #000;
}

.toast_1 .sidebar {
  width: 0.5rem;
}

.toast_1 .content_1,
.toast_1 .header_1,
.toast_1 .text {
  width: 100%;
  display: flex;
}

.toast_1 .content_1 {
  padding-left: 40px;
}

.toast_1 .header_1,
.toast_1 .text {
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.toast_1 .header_1 {
  padding-left: 40px;
  padding-right: 10px;
  border-radius: 20px;
  height: 25px;
  margin-bottom: 5px;
}

.toast_1 .header_1 button {
  margin-left: auto;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 1rem;
}

.toast_1 .header_1 button:hover {
  background: var(--light-grey);
}

.toast_1 .content_1 {
  flex-grow: 1;
}
</style>
