import axios from "axios";

const state = {
  customer_account_id: "",
  response: "",
  additional_information: "",
  number_of_participants: "",
  date_of_conference_start: "",
  date_of_conference_end: "",
  account_id: "",
  contact_id: "",
  account_name: "",

  opportunity_id: "",
  quote_id: "",
  bidder_id: "",
  booking_id: "",
  // opportunity_id: this.$route.params.id,
  // quote_id: this.$route.params.qID,
  // bidder_id: this.$route.params.bidderID,
  // booking_id: this.$route.params.bookingID,
  respnse_booking: null,
  disabled_button: false,
};
const mutations = {
  SET_PRELIMINARY_BOOKING_DETAILS(state, dataDetails) {
    console.log('SET_PRELIMINARY_BOOKING_DETAILS-*-',dataDetails);
    const response = dataDetails.response
    const quoteData = dataDetails.quote.data
    if (response.response) {
      state.disabled_button = true;
    }else{
      state.disabled_button = false;
    }
    state.response = response.response;
    state.additional_information =
    response.additional_information;

    state.customer_email = quoteData.opportunity_detail.email;
    state.number_of_participants = quoteData.opportunity_detail.number_of_participants;
    // state.free_text_message = quoteData.opportunity_detail.
    state.date_of_conference_start = quoteData.opportunity_detail.date.slice(0, 10);
    state.date_of_conference_end = quoteData.opportunity_detail.date.slice(14, 24);
    state.customer_account_id = quoteData.opportunity_detail.account_id;
    state.contact_id = quoteData.opportunity_detail.contact_id;

    if(dataDetails.quote.comeFrom == 'quote'){
      state.account_name = quoteData.customer_account_detail[0].name;
  
      state.account_id = quoteData.account_id;
  
      state.bidder_id = quoteData.bidder_id;
      state.opportunity_id = quoteData.opportunity_id;
      state.quote_id = quoteData.quote_id;
      state.booking_id = quoteData.preliminary_booking_id;
    }else{
      state.account_name = quoteData.quote_detail.customer_account_detail[0].name;
  
      state.account_id = quoteData.quote_detail.account_id;
  
      state.bidder_id = quoteData.quote_detail.bidder_id;
      state.opportunity_id = quoteData.quote_detail.opportunity_id;
      state.quote_id = quoteData.quote_detail.quote_id;
      state.booking_id = quoteData.quote_detail.preliminary_booking_id;
    }


  },
};
const actions = {
  GetPreliminaryBookingByID({ commit, rootGetters }, data) {
    console.log('GetPreliminaryBookingByID--',data);
    commit("SET_TOTAL_APIS", 1);
    // *************GET Priliminary booking details**********
    const id = data.comeFrom == "quote" ? data.data.preliminary_booking_id : data.data.quote_detail.preliminary_booking_id
    return new Promise((resolve, reject) => {
      axios
        .get(axios.defaults.baseURL + "get-preliminary-booking/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          commit("SET_PRELIMINARY_BOOKING_DETAILS", {response: res.data.data[0], quote: data});
          const G_Total_Apis = rootGetters["G_Total_Apis"];

          var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
          G_Completed_APICalls++;
          const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
          commit("SET_PROGRESS", progress);
          commit("SET_COMPLETED_APIS", G_Completed_APICalls);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
};
const getters = {
    getPreliminaryBooking: state => state
};

export default {
  state,
  mutations,
  actions,
  getters,
};
