import axios from "axios";

const state = {
  all_contacts_Details: "",
  single_Contacts_Details: "",
  checkVariale: "nishant",
};
const mutations = {
  SET_ALL_CONTACTS_DETAILS(state, data) {
    state.all_contacts_Details = data;
  },
  SET_CONTACTS_DETAILS_BY_ID(state, data) {
    state.single_Contacts_Details = data;
  },
};
const actions = {
  GetContacts({ commit, rootGetters }) {
    commit("SET_TOTAL_APIS", 1);
    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "contacts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_ALL_CONTACTS_DETAILS", res.data.data);
        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);
        // this.accountDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });
  },
  GetContactById({ commit, rootGetters },ID) {
    commit("SET_TOTAL_APIS", 1);
    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "contact/" + ID, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        commit("SET_CONTACTS_DETAILS_BY_ID", res.data.data[0]);
        const G_Total_Apis = rootGetters["G_Total_Apis"];
        var G_Completed_APICalls = rootGetters["G_Completed_APICalls"];
        G_Completed_APICalls++;
        const progress = (G_Completed_APICalls / G_Total_Apis) * 100;
        commit("SET_PROGRESS", progress);
        commit("SET_COMPLETED_APIS", G_Completed_APICalls);
        // this.accountDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });
  },
};
const getters = {
  checkVariale(state) {
    console.log("state", state);
    console.log("state.checkVariale", state.checkVariale);
    return state.checkVariale;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
