<!-- PartnerLayout.vue -->
<template>
    <div id="main-wrapper">
      <div class="nav-header">
      <router-link to="" class="brand-logo">
        <img
          src="images/logos/konferensbokarna-2.svg"
          style="width: 40px"
          alt=""
        />
      </router-link>
    </div>
      <Header />
      <router-view></router-view>
      <div class="footer">
        <div class="copyright">
          <p>Copyright © 2023 &amp; Konferensbokarna</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Header from "../components/customerHeader";
  
  export default {
    name: "Customer Layout",
    components: { Header },
  };
  </script>
  